<template>
  <v-snackbar color="green" top right :value="show" timeout="7000">
    {{ message }}
  </v-snackbar>
</template>

<script>
export default {
  name: "AppSnackbar",
  props: {
    message: {
      type: String,
    },
    show: {
      type: Boolean,
    },
  },
};
</script>

<style>
</style>