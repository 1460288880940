<template>
  <div class="smart-city">
    <div class="hero-container">
      <PageHero :img="img" :alt="alt">
        <h1 slot="hero-title" class="hero-title">
          {{ $t("smartCity.hero.title") }}
        </h1>
        <p
          slot="hero-text"
          class="hero-text"
          v-html="$t('smartCity.hero.text')"
        ></p>
        <p slot="hero-cta" class="hero-cta">{{ $t("smartCity.hero.cta") }}</p>
      </PageHero>
    </div>
    <div class="about-container">
      <AboutSection />
    </div>
    <div class="newsletter-container">
      <SectionTitle
        :title="$t('smartCity.newsletter.title')"
        :subtitle="$t('smartCity.newsletter.subtitle')"
      />
      <NewsletterForm
        :nameLabel="$t('smartCity.newsletter.name')"
        :emailLabel="$t('smartCity.newsletter.email')"
        :buttonText="$t('smartCity.newsletter.btn')"
      />
    </div>
    <div class="smart-city-cards-container">
      <div
        class="parallax-bg"
        slot="parallax-bg"
        data-swiper-parallax="-23%"
      ></div>
      <swiper
        class="swiper"
        :options="swiperSmartCityOption"
        data-aos="fade-up"
      >
        <swiper-slide v-for="(card, i) in cards" :key="i">
          <SmartCityCard
            :class="card.class"
            :cardImg="card.img"
            :cardTitle="$t(card.title)"
            :cardSubtitle="$t(card.subtitle)"
            :cardText="$t(card.text)"
          />
        </swiper-slide>
        <div class="swiper-pagination" slot="pagination"></div>
        <div class="swiper-button-prev" slot="button-prev"></div>
        <div class="swiper-button-next" slot="button-next"></div>
      </swiper>
    </div>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import "swiper/css/swiper.css";

import PageHero from "@/components/PageHero";
import AboutSection from "@/components/AboutSection";
import SectionTitle from "@/components/SectionTitle";
import SmartCityCard from "@/components/elements/SmartCityCard";
import NewsletterForm from "@/components/NewsletterForm";
export default {
  name: "SmartCity",
  components: {
    Swiper,
    SwiperSlide,
    PageHero,
    AboutSection,
    SectionTitle,
    SmartCityCard,
    NewsletterForm,
  },
  data() {
    return {
      swiperSmartCityOption: {
        speed: 600,
        loop: true,
        slidesPerView: 1,
        spaceBetween: 10,
        autoplay: {
          delay: 12000,
          disableOnInteraction: false,
        },
        pagination: {
          el: ".swiper-pagination",
          type: "bullets",
        },
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
        breakpoints: {
          1220: {
            slidesPerView: 3,
          },
          940: {
            slidesPerView: 2,
          },
        },
      },
      img: "smart-city.png",
      alt: "Computer",
      cards: [
        {
          img: "contagem_e_classificacao.png",
          subtitle: "smartCity.carousel.count.subtitle",
          text: "smartCity.carousel.count.text",
        },
        {
          img: "controle_de_fluxo_de_trafego.png",
          subtitle: "smartCity.carousel.control.subtitle",
          text: "smartCity.carousel.control.text",
        },
        {
          img: "estacionamento_inteligente.png",
          subtitle: "smartCity.carousel.parking.subtitle",
          text: "smartCity.carousel.parking.text",
        },
        {
          img: "seguranca_de_transito.png",
          subtitle: "smartCity.carousel.security.subtitle",
          text: "smartCity.carousel.security.text",
        },
        {
          img: "pedagio_automatizado.png",
          subtitle: "smartCity.carousel.toll.subtitle",
          text: "smartCity.carousel.toll.text",
        },
        {
          img: "mapeamento_de_fluxo_de_pedestres.png",
          subtitle: "smartCity.carousel.pedestrians.title",
          text: "smartCity.carousel.pedestrians.text",
        },
        {
          img: "seguranca_publica.png",
          subtitle: "smartCity.carousel.publicSecurity.title",
          text: "smartCity.carousel.publicSecurity.text",
        },
      ],
    };
  },
};
</script>

<style scoped lang="scss">
.smart-city {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 2rem;

  .hero-container,
  .about-container,
  .newsletter-container {
    width: 100%;
    background: var(--white);
    border-radius: 10px;
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.1);
  }

  .hero-container {
    .hero-title {
      font-size: var(--text-44);
      font-weight: var(--text-semibold);
      color: var(--gray-80);
    }

    .hero-text {
      max-width: 700px;
      font-size: var(--text-18);
      color: var(--gray-20);
      line-height: 1.8;
    }

    .hero-cta {
      font-size: var(--text-18);
      font-weight: var(--text-semibold);
      color: var(--gray-80);
    }
  }

  .about-container {
    padding-top: 0.5rem;
    background: #fff
      url("data:image/svg+xml,%3Csvg width='615' height='554' xmlns='http://www.w3.org/2000/svg'%3E %3Cg fill='none' fill-rule='evenodd'%3E %3Cpath d='M463.283 528.726c45.144 0 95.716 8.237 151.717 24.712V0H41.584C28.58 76.038-4.694 126.702.957 203.448c16.342 221.959 215.179 325.278 462.326 325.278z' fill='%233487ac05'/%3E %3Cpath d='M382.319 485.092c54.57 14.534 187.124 6.139 232.681-13.977V0H122.36c-16.232 41.585 18.088 107.04 18.088 154.372 0 203.855 45.087 278.309 241.87 330.72z' fill='%233487ac04'/%3E %3Cpath d='M443.883 440.71c54.208 0 128.702-54.567 171.117-81.896V0H231.198c-21.166 38.7-14.497 103.867-14.497 151.086 0 150.221 76.96 289.624 227.182 289.624z' fill='%233487ac05'/%3E %3C/g%3E %3C/svg%3E")
      top right no-repeat fixed;
  }

  .smart-city-cards-container {
    width: 100%;
    display: flex;
    justify-content: center;
    box-shadow: 0px 0px 4px 1px rgba(0, 0, 0, 0.1);
    background: var(--gray-30);
    border-radius: 10px;
    padding: 1rem 0 0;

    .swiper {
      width: 100%;
      display: flex;
      justify-content: center;
      background: var(--gray-30);
      padding-bottom: 1rem;

      .swiper-slide {
        padding-bottom: 2rem;
        display: flex;
        justify-content: center;
      }
    }
  }

  .newsletter-container {
    margin-bottom: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 2rem 0 3rem;
  }
}

@media screen and (max-width: 1200px) {
  .smart-city {
    .hero-container {
      .hero-title {
        font-size: var(--text-30);
      }

      .hero-subtitle,
      .hero-text,
      .hero-cta {
        font-size: var(--text-18);
        max-width: 900px;
      }
    }
  }
}

@media screen and (max-width: 900px) {
  .smart-city {
    .hero-container {
      .hero-title {
        font-size: var(--text-28);
        line-height: 1.6;
      }

      .hero-subtitle {
        font-size: var(--text-16);
        line-height: 1.7;
        max-width: 600px;
      }

      .hero-text,
      .hero-cta {
        font-size: var(--text-16);
        line-height: 1.6;
      }
    }

    .planning-container {
      padding: 0.5rem 0;
    }
  }
}
</style>
