<template>
  <div class="payment-instructions">
    <h1 class="payment-title">{{ $t("payment.title") }}</h1>
    <div class="instructions-cards">
      <PaymentInstructionCard
        class="payment-instruction-card"
        v-for="(instruction, index) in instructions"
        :key="index"
        :image="instruction.image"
        :title="$t(instruction.title)"
        :instruction="$t(instruction.instruction)"
      />
    </div>
  </div>
</template>

<script>
import PaymentInstructionCard from "../../components/elements/PaymentInstructionCard.vue";
export default {
  name: "PaymentInstructions",
  components: { PaymentInstructionCard },
  data() {
    return {
      instructions: [
        {
          image: "1",
          title: "payment.cards.account.title",
          instruction: "payment.cards.account.instruction",
        },
        {
          image: "2",
          title: "payment.cards.paymentMenu.title",
          instruction: "payment.cards.paymentMenu.instruction",
        },
        {
          image: "3",
          title: "payment.cards.findButton.title",
          instruction: "payment.cards.findButton.instruction",
        },
        {
          image: "4",
          title: "payment.cards.secureAccess.title",
          instruction: "payment.cards.secureAccess.instruction",
        },
        {
          image: "5",
          title: "payment.cards.selectPayment.title",
          instruction: "payment.cards.selectPayment.instruction",
        },
        {
          image: "6",
          title: "payment.cards.confirmation.title",
          instruction: "payment.cards.confirmation.instruction",
        }
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
.payment-instructions {
  background: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
  margin-bottom: 2rem;

  .payment-title {
    font-size: var(--text-40);
    font-weight: var(--text-regular);
  }
  .instructions-cards {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 4rem;
    .payment-instruction-card {
      max-width: 360px;
    }
  }
}

@media screen and (max-width: 1300px) {
  .payment-instructions {
    .instructions-cards {
      grid-template-columns: repeat(2, 1fr);
    }
  }
}

@media screen and (max-width: 680px) {
  .payment-instructions {
    .instructions-cards {
      grid-template-columns: 1fr;
    }
  }
}
</style>