import { httpClient } from "./index";

export const newsletter = ({ 
  name = null, 
  email = null 
}) => httpClient.post("/newsletter/subscribe", {
    newsletter : {
      name: name, 
      email: email
    }
  });
