<template>
  <div class="metrics">
    <div class="hero-container">
      <PageHero :img="img" :alt="alt">
        <h1 slot="hero-title" class="hero-title">OOH Data Driven</h1>
        <p
          slot="hero-subtitle"
          class="hero-subtitle"
          v-html="$t('metrics.hero.subtitle')"
        ></p>
        <p
          slot="hero-text"
          class="hero-text"
          v-html="$t('metrics.hero.text')"
        ></p>
        <p slot="hero-cta" class="hero-cta">
          {{ $t("metrics.hero.cta") }}
          <router-link to="contact" class="hero-cta-btn">{{
            $t("metrics.hero.contact")
          }}</router-link>
        </p>
      </PageHero>
    </div>
    <div class="planning-container">
      <SectionTitle
        :title="$t('metrics.planning.title')"
        :subtitle="$t('metrics.planning.subtitle')"
      />
      <div class="planning-content">
        <PlanningCard
          class="planning-card"
          v-for="(card, index) in planningCards"
          :key="index"
          :icon="card.icon"
          :title="$t(card.title)"
          :firstParagraph="$t(card.firstParagraph)"
          :secondParagraph="$t(card.secondParagraph)"
          :img="card.img"
          :cardClass="card.class"
        />
      </div>
    </div>
    <div class="demo-form-container">
      <AccessDemoForm data-aos="fade-up" />
    </div>
  </div>
</template>

<script>
import PageHero from "@/components/PageHero";
import SectionTitle from "@/components/SectionTitle";
import PlanningCard from "@/components/elements/PlanningCard";
import AccessDemoForm from "@/components/AccessDemoForm";

export default {
  name: "Metrics",
  components: {
    PageHero,
    SectionTitle,
    PlanningCard,
    AccessDemoForm,
  },
  data() {
    return {
      cta: "",
      img: "metricas-de-audiencia-principal-min.png",
      alt: "Computer",
      planningCards: [
        {
          icon: "data_storage.svg",
          title: "metrics.planning.cards.collectedData.title",
          firstParagraph: "metrics.planning.cards.collectedData.firstParagraph",
          secondParagraph:
            "metrics.planning.cards.collectedData.secondParagraph",
          img: "dados-coletados.png",
        },
        {
          icon: "sitemap.svg",
          title: "metrics.planning.cards.segmentation.title",
          firstParagraph: "metrics.planning.cards.segmentation.firstParagraph",
          secondParagraph:
            "metrics.planning.cards.segmentation.secondParagraph",
          img: "segmentacao.png",
          class: "invert",
        },
        {
          icon: "target.svg",
          title: "metrics.planning.cards.performance.title",
          firstParagraph:
            "metrics.planning.cards.performance.firstParagraph",
          secondParagraph: "metrics.planning.cards.performance.secondParagraph",
          img: "comprovacao-performance.png",
        },
      ],
    };
  },
};
</script>

<style scoped lang="scss">
.metrics {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 2rem;

  .hero-container {
    width: 100%;
    background: var(--white);
    background-image: linear-gradient(120deg, #fefefe 0%, #f9f9f9 100%);
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.1);
    border-radius: 10px;

    .hero-title {
      font-size: var(--text-44);
      font-weight: var(--text-semibold);
      color: var(--gray-80);
    }

    .hero-subtitle {
      font-size: var(--text-16);
      color: var(--gray-20);
      line-height: 1.6;
      opacity: 0.6;
      letter-spacing: 0.15px;
    }

    .hero-text {
      max-width: 700px;
      font-size: var(--text-18);
      color: var(--gray-20);
      line-height: 1.8;
    }

    .hero-cta {
      font-size: var(--text-20);
      font-weight: var(--text-medium);
      color: var(--gray-80);
      max-width: 700px;

      .hero-cta-btn {
        color: var(--blue-50);
        text-decoration: underline;
        font-weight: var(--text-semibold);

        &:hover {
          color: var(--blue-100);
        }
      }
    }
  }

  .planning-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 0;
    padding: 0.5rem 0;
    background: var(--white);
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.1);
    border-radius: 10px;

    .planning-content {
      width: 100%;

      .invert {
        width: 100%;
        background: var(--gray-30);
      }
    }
  }

  .demo-form-container {
    width: 100%;
    box-shadow: 0px 0px 4px 1px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    background: var(--white);
    padding: 2rem 0 3rem;
    margin-bottom: 2rem;
  }
}

@media screen and (max-width: 1340px) {
  .metrics {
    .hero-container {
      padding: 2rem 1rem;
    }
  }
}

@media screen and (max-width: 900px) {
  .metrics {
    .hero-container {
      padding: 2rem 4rem;

      .hero-title {
        font-size: var(--text-30);
        text-align: center;
      }
    }

    .planning-container {
      padding: 0.5rem 0;
    }
  }
}

@media screen and (max-width: 540px) {
  .metrics {
    .hero-container {
      padding: 2rem;

      .hero-title {
        font-size: var(--text-26);
      }

      .hero-subtitle {
        font-size: var(--text-14);
        letter-spacing: 0.25px;
      }

      .hero-text,
      .hero-cta {
        font-size: var(--text-16);
      }
    }
  }
}
</style>
