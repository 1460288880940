<template>
  <div class="solution-card" data-aos="fade-up">
    <div class="right-content">
      <div class="text-content">
        <h3 class="card-title">{{ cardTitle }}</h3>
        <p class="card-text">
          {{ cardText }}
        </p>
      </div>
      <div class="panels">
        <slot name="panel"></slot>
      </div>
      <div class="cta-container">
        <p>
          {{ cardCTA }}
        </p>
        <slot name="cta-button"></slot>
      </div>
    </div>
    <div class="left-content">
      <img
        class="card-img"
        :src="require(`@/assets/img/${cardImg}`)"
        :alt="cardImg"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: "SolutionCard",
  components: {},
  props: {
    cardTitle: String,
    cardText: String,
    cardCTA: String,
    buttonContent: Object,
    cardImg: String,
  },
  data() {
    return {};
  },
};
</script>

<style scoped lang="scss">
.solution-card {
  width: 100%;
  display: flex;
  justify-content: space-between;
  
  &:nth-child(even) {
    direction: rtl;
  }

  .right-content {
    direction: initial;
    flex-basis: 100%;

    .text-content {
      max-width: 580px;
      .card-title {
        font-size: var(--text-26);
        font-weight: var(--text-semibold);
        color: var(--gray-80);
        margin-bottom: 1rem;
      }

      .card-text {
        font-size: var(--text-16);
        color: var(--gray-20);
        line-height: 1.7;
        letter-spacing: 0.15px;
        padding-bottom: 1.5rem;
      }
    }

    .panels {
      width: 100%;
    }

    .cta-container {
      margin-top: 1.5rem;
    }
  }

  .left-content {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-basis: 100%;
    text-align: center;
    max-width: 100%;
    height: 608px;

    .card-img {
      max-width: 640px;
      width: 100%;
    }
  }
}

@media screen and (max-width: 1340px) {
  .solution-card {
    padding: 1rem;
    gap: 0;
    flex-direction: column;
    &:nth-child(even) {
      direction: initial;
    }

    .right-content {
      .text-content {
        .card-title {
          font-size: var(--text-22);
          margin-bottom: 0.5rem;
        }

        .card-text {
          font-size: var(--text-14);
        }
      }

      .cta-container {
        p {
          font-size: var(--text-14);
        }
      }
    }

    .left-content {
      .card-img {
        max-width: 100%;
        width: 400px;
      }
    }
  }
}
</style>
