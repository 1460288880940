<template>
  <div class="card">
    <div class="card-text-container">
      <h3 class="card-title">{{ cardTitle }}</h3>
      <h4 class="card-subtitle">{{ cardSubtitle }}</h4>
      <p class="card-text">{{ cardText }}</p>
    </div>
    <div class="img-container">
      <img
        class="card-img"
        :src="require(`../../assets/img/${cardImg}`)"
        :alt="cardImg"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: "SmartCityCard",
  props: {
    cardImg: {
      type: String,
    },
    cardTitle: {
      type: String,
    },
    cardSubtitle: {
      type: String,
    },
    cardText: {
      type: String,
    },
  },
};
</script>

<style lang="scss" scoped>
.card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 5px;
  background-color: #fff;
  text-align: center;
  padding: 1rem;
  max-width: 580px;
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.1);

  .card-img {
    max-width: 100%;
    width: 500px;
    box-shadow: 0 0 10px 2px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
  }
  .card-text-container {
    text-align: center;
    padding-bottom: 1rem;
    display: flex;
    flex-direction: column;

    .card-title {
      font-size: var(--text-22);
      font-weight: var(--text-semibold);
      margin-bottom: .5rem;
    }

    .card-subtitle {
      color: var(--gray-20);
      font-size: var(--text-16);
      margin-bottom: 1rem;

    }

    .card-text {
      max-width: 640px;
      font-size: var(--text-16);
      line-height: 1.7;
      padding: 0 1rem 0 3rem;
      margin-bottom: 0;
      text-align: left;
    }
  }
}

@media screen and (max-width: 1200px) {
  .card {
    flex-direction: column;
    .card-text-container {
      padding: 1rem;

      .card-text {
        font-size: var(--text-16);
        line-height: 28px;
        padding: 0 2rem;
      }
    }
  }
}

@media screen and (max-width: 900px) {
  .card {
    .card-text-container {
      padding: 0;
      padding-bottom: 2rem;
      .card-text {
        padding: 0;
      }
    }
  }
}
</style>
