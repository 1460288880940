import Vue from "vue";
import Vuex from "vuex";

import { Newsletter, LeadSignUp, Contact, Blog } from "./Modules";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    Newsletter,
    LeadSignUp,
    Contact,
    Blog,
  },
});
