<template>
  <div class="faq">
    <div class="content">
      <v-expansion-panels class="panels">
        <v-expansion-panel
          class="panel"
          v-for="(question, i) in questions"
          :key="i"
        >
          <v-expansion-panel-header class="panel-header">
            {{ $t(question.ask) }}
          </v-expansion-panel-header>
          <v-expansion-panel-content class="panel-content">
            {{ $t(question.answer) }}
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </div>
  </div>
</template>

<script>
export default {
  name: "FAQ",
  components: {},
  data() {
    return {
      questions: [
        {
          ask: "faq.questions.q1",
          answer: "faq.questions.a1",
        },
        {
          ask: "faq.questions.q2",
          answer: "faq.questions.a2",
        },
        {
          ask: "faq.questions.q3",
          answer: "faq.questions.a3",
        },
        {
          ask: "faq.questions.q4",
          answer: "faq.questions.a4",
        },
        {
          ask: "faq.questions.q5",
          answer: "faq.questions.a5",
        },
        {
          ask: "faq.questions.q6",
          answer: "faq.questions.a6",
        },
        {
          ask: "faq.questions.q7",
          answer: "faq.questions.a7",
        },
        {
          ask: "faq.questions.q8",
          answer: "faq.questions.a8",
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
.faq {
  display: flex;
  align-items: center;
  margin: 2rem 0 0.7rem;
  border-radius: 10px;
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.1);
  background: url("../assets/img/FAQ.png") no-repeat;
  background-position: left;
  background-size: center;
  background-color: rgba(188, 199, 207, 0.1);

  .content {
    width: 100%;
    padding: 20px;
    display: flex;
    justify-content: flex-end;
    gap: 2rem;

    .panels {
      gap: 1rem;
      max-width: 60%;
      .panel {
        box-shadow: 0;
        border-radius: 5px;
        .panel-header {
          font-size: var(--text-16);
          line-height: 20px;
        }

        .panel-content {
          width: 90%;
          color: var(--gray-20);
        }
      }
    }
  }
}

@media screen and (max-width: 1200px) {
  .faq {
    .content {
      .panels {
        .panel {
          .panel-header {
            font-size: var(--text-14);
          }

          .panel-content {
            font-size: var(--text-14);
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1080px) {
  .faq {
    padding-top: 20px;
    .content {
      flex-direction: column;
      align-items: center;
      .faq-img {
        width: 100%;
        max-width: 600px;
      }

      .panels {
        max-width: 800px;
        .panel {
          .panel-content {
            width: 100%;
          }
        }
      }
    }
  }
}
</style>
