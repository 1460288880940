<template>
  <div class="about">
    <SectionTitle
      :title="$t('smartCity.about.title')"
      :subtitle="$t('smartCity.about.subtitle')"
    />
    <div class="content">
      <div class="img-wrapper">
        <img
          data-aos="fade-up"
          class="banner"
          src="@/assets/img/smart-city-inicial-min.png"
          alt="Banner"
        />
      </div>
      <div class="text-wrapper">
        <p
          class="text"
          v-for="(paragraph, index) in text"
          :key="index"
          data-aos="fade-up"
        >
          {{ $t(paragraph) }}
        </p>
      </div>
    </div>
    <div class="cards">
      <AboutCard
        class="card"
        v-for="(item, i) in cards"
        :key="i"
        :icon="item.icon"
        :title="$t(item.title)"
        :firstParagraph="$t(item.firstParagraph)"
        :secondParagraph="$t(item.secondParagraph)"
      />
    </div>
  </div>
</template>

<script>
import SectionTitle from "@/components/SectionTitle";
import AboutCard from "@/components/elements/AboutCard";

export default {
  name: "About",
  components: {
    SectionTitle,
    AboutCard,
  },
  data: () => {
    return {
      text: {
        firstParagraph: "smartCity.about.texts.firstParagraph",
        secondParagraph: "smartCity.about.texts.secondParagraph",
        thirdParagraph: "smartCity.about.texts.thirdParagraph",
        fourthParagraph: "smartCity.about.texts.fourthParagraph",
        cta: "smartCity.about.texts.cta",
      },
      cards: [
        {
          icon: "users.svg",
          title: "smartCity.about.cards.society.title",
          firstParagraph: "smartCity.about.cards.society.texts.firstParagraph",
          secondParagraph: "smartCity.about.cards.society.texts.secondParagraph",
        },
        {
          icon: "trees.svg",
          title: "smartCity.about.cards.environment.title",
          firstParagraph: "smartCity.about.cards.environment.texts.firstParagraph",
          secondParagraph: "smartCity.about.cards.environment.texts.secondParagraph",
        },
        {
          icon: "money.svg",
          title: "smartCity.about.cards.economy.title",
          firstParagraph: "smartCity.about.cards.economy.texts.firstParagraph",
          secondParagraph: "smartCity.about.cards.economy.texts.secondParagraph",
        },
        {
          icon: "transport.svg",
          title: "smartCity.about.cards.mobility.title",
          firstParagraph: "smartCity.about.cards.mobility.texts.firstParagraph",
          secondParagraph: "smartCity.about.cards.mobility.texts.secondParagraph",
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
.about {
  max-width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 60px;

  .content {
    width: 100%;
    display: flex;
    justify-content: space-around;
    padding: 0 80px;
    margin-bottom: 80px;
    margin-top: 40px;

    .img-wrapper {
      max-width: 100%;
      text-align: center;

      .banner {
        max-width: 100%;
        width: 850px;
      }
    }

    .text-wrapper {
      max-width: 600px;
      .text {
        font-size: var(--text-16);
        color: var(--gray-80);
        line-height: 1.7;
        padding-bottom: 10px;
        position: relative;

        &:last-child {
          padding: 0;
          font-weight: var(--text-semibold);
          &::before {
            content: "";
            width: 5px;
            height: 100%;
            background: var(--gray-30);
            position: absolute;
            left: -10px;
          }
        }
      }
    }
  }

  .cards {
    display: grid;
    grid-template-columns: 1fr 1fr;
    justify-items: center;
    gap: 3rem 5rem;
    padding: 0 2rem;

    .card {
      padding: 0 3rem;
    }
  }
}

@media screen and (max-width: 1200px) {
  .about {
    .content {
      flex-direction: column;
      .text-wrapper {
        max-width: 100%;
        .text {
          padding-bottom: 0;
          margin-bottom: 25px;
        }
      }
    }
  }
}

@media screen and (max-width: 900px) {
  .about {
    .content {
      padding: 0 40px;
      margin-bottom: 40px;
      .text-wrapper {
        .text {
          font-size: var(--text-16);
        }
      }
    }

    .cards {
      grid-template-columns: 1fr;
      gap: 2rem 0;
      padding: 0;

      .card {
        padding: 0 40px;
      }
    }
  }
}
</style>
