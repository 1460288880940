<template>
  <form
    @submit.prevent="handleFormSubmit"
    class="newsletter-form"
    data-aos="fade-up"
  >
    <div class="input-fields">
      <v-text-field
        outlined
        :dense="dense"
        :color="inputColor"
        class="name"
        :label="nameLabel"
        type="text"
        name="newsletter[name]"
        v-model="name"
        required
      >
      </v-text-field>
      <v-text-field
        outlined
        :dense="dense"
        :color="inputColor"
        class="email"
        :label="emailLabel"
        type="email"
        name="newsletter[email]"
        v-model="email"
        required
      >
      </v-text-field>
    </div>
    <v-btn
      :loading="loading"
      @click="validate"
      type="submit"
      :color="buttonColor"
      :height="buttonHeight"
      class="btn"
    >
      {{ buttonText }}
    </v-btn>
    <AppSnackbar v-model="snackbar" :show="message" :message="message" />
  </form>
</template>

<script>
import { mapState, mapActions } from "vuex";
import AppSnackbar from "@/components/elements/AppSnackbar";

export default {
  name: "NewsletterForm",
  components: { AppSnackbar },
  props: {
    nameLabel: {
      type: String,
    },
    emailLabel: {
      type: String,
    },
    inputColor: {
      type: String,
      default: "#3487ac",
    },
    buttonText: {
      type: String,
    },
    buttonColor: {
      type: String,
      default: "#3487ac",
    },
    buttonHeight: {
      type: String,
      default: "56",
    },
    dense: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      name: null,
      email: null,
      snackbar: false,
      loading: false,
      loader: null,
    };
  },
  watch: {
    loader() {
      const l = this.loader;
      this[l] = !this[l];

      setTimeout(() => (this[l] = false), 3000);

      this.loader = null;
    },
  },
  computed: {
    ...mapState("Newsletter", {
      success: (state) => state.success,
      message: (state) => state.message,
    }),
  },
  methods: {
    ...mapActions("Newsletter", ["submitForm"]),
    handleFormSubmit() {
      const payload = {
        name: this.name,
        email: this.email,
      };
      this.submitForm(payload);
      this.resetForm();
    },
    resetForm() {
      this.name = null;
      this.email = null;
    },
    validate() {
      if (this.name && this.email) {
        this.snackbar = true;
        this.loader = "loading";
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.newsletter-form {
  max-width: 100%;
  display: grid;
  gap: 0.5rem;
  margin-top: 5px;
  display: flex;

  .input-fields {
    display: flex;
    gap: 0.5rem;
  }

  .btn {
    color: var(--white);
  }
}

@media screen and (max-width: 500px) {
  .newsletter-form {
    flex-direction: column;
    padding-bottom: 1rem;

    .input-fields {
      flex-direction: column;
      gap: 0;
    }

    .email,
    .btn {
      margin-top: -20px;
    }
  }
}
</style>
