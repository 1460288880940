<template>
  <v-app>
      <Layout />
  </v-app>
</template>

<script>
import Layout from "@/views/Base/Layout"
export default {
  name: "App",
  components: {
    Layout
  },
};
</script>

<style>

</style>
