import { httpClient } from "./index";

export const contact = ({
  name = null,
  email = null,
  company = null,
  message = null,
}) =>
  httpClient.post("/contacts/save", {
    contact: {
      name: name,
      email: email,
      company: company,
      message: message,
    },
  });
