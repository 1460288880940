import { contact } from "../../../api/contact.api";
import i18n from "../../../i18n";

export const Contact = {
  state: {
    contact: {
      name: null,
      email: null,
      company: null,
      message: null,
    },
    success: false,
    message: null,
  },

  mutations: {
    SET_FORM_DATA(state, { name, email, company, message }) {
      state.contact.name = name;
      state.contact.email = email;
      state.contact.company = company
      state.contact.message = message;
    },
  },

  actions: {
    async submitForm({ state, commit }, payload) {
      try {
        const { data } = await contact(payload);
        state.message = i18n.t("contact.response");
        state.success = data.success;
        commit("SET_FORM_DATA", data);
        console.log(data);
        setTimeout(function() {
          state.message = null;
        }, 7000);
      } catch (error) {
        console.log(error);
      }
    },
  },
  getters: {},
  namespaced: true,
};
