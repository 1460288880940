import Vue from "vue";
import VueRouter from "vue-router";
import Home from "@/views/Home";
import Metrics from "@/views/Metrics";
import SmartCity from "@/views/SmartCity";
import FAQ from "@/views/FAQ";
import Contact from "@/views/Contact";
import PrivacyPolicy from "@/views/PrivacyPolicy";
import PaymentInstructions from "@/views/Payment/PaymentInstructions";
import NotFound from "@/views/NotFound";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/metrics",
    name: "Metricas",
    component: Metrics,
  },
  {
    path: "/smart-city",
    name: "Smart City",
    component: SmartCity,
  },
  // {
  //   path: "/blog",
  //   name: "Blog",
  //   component: () => import(/* webpackChunkName: "Blog"*/ "../views/Blog"),
  // },
  {
    path: "/faq",
    name: "FAQ",
    component: FAQ,
  },
  {
    path: "/contact",
    name: "Contato",
    component: Contact,
  },
  {
    path: "/privacy-policy",
    name: "Privacy Policy",
    component: PrivacyPolicy,
  },
  {
    path: "/payment",
    name: "Payment Instructions",
    component: PaymentInstructions,
  },
  {
    path: "/404",
    alias: "*",
    name: "notFound",
    component: NotFound,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
});

export default router;
