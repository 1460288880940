<template>
  <div class="page-loader" v-if="!isLoaded">
    <div class="c-loader"></div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isLoaded: false,
    };
  },
  mounted() {
    document.onreadystatechange = () => {
      if (document.readyState === "complete") {
        this.isLoaded = true;
      }
    };
  },
};
</script>

<style lang="scss" scoped>
.page-loader {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background-color: #fff;
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;

  .c-loader {
    animation: is-rotating 1s infinite;
    border: 6px solid #e5e5e5;
    border-radius: 50%;
    border-top-color: #3487ac;
    height: 50px;
    width: 50px;
  }
}

@keyframes is-rotating {
  to {
    transform: rotate(1turn);
  }
}
</style>