<template>
  <div class="container">
    <h1 class="privacy-title">{{$t('privacyPolicy.title')}}</h1>
    <h4>{{$t('privacyPolicy.subtitle')}}</h4>
    <p class="privacy-text">
      {{$t('privacyPolicy.text')}}
    </p>

    <ul class="privacy-terms">
      <li>{{$t('privacyPolicy.points.p1')}}</li>
      <li>{{$t('privacyPolicy.points.p2')}}</li>
      <li>{{$t('privacyPolicy.points.p3')}}</li>
      <li>{{$t('privacyPolicy.points.p4')}}</li>
      <li>{{$t('privacyPolicy.points.p5')}}</li>
      <li>{{$t('privacyPolicy.points.p6')}}</li>
      <li>{{$t('privacyPolicy.points.p7')}}</li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "PrivacyPolicy",
};
</script>

<style lang="scss" scoped>
.container {
  margin: 2rem 0 .7rem;
  background: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.1);
  padding: 2rem;

  .privacy-title {
    text-align: center;
    margin-bottom: 2rem;
  }

  .privacy-text {
    max-width: 1200px;
    margin: .5rem 0 1rem;
    line-height: 28px;
  }

  .privacy-terms li {
    padding: 0.5rem 0;

    &::before {
      content: "\2022 ";
      color: var(--blue-50);
      padding-right: 0.5em;
      font-size: 20px;
    }
  }
}
</style>
