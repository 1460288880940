<template>
  <div class="latest-posts" v-if="posts.length">
    <h2 class="section-title">{{ $t("layout.static.latestPosts") }}</h2>
    <div class="posts">
      <swiper class="swiper" :options="swiperPostsOption">
        <swiper-slide
          class="slide-item"
          v-for="(post, index) in posts"
          :key="index"
        >
          <PostPreview
            :link="`https://blog.datavisiooh.com/posts/${post.slug}`"
            :image="post.image"
            :date="formatDate(post.created_at)"
            :category="post.category"
            :title="post.title"
            :description="
              post.body.replace(/<[^>]*>?/gm, '').substring(0, 140) + '...'
            "
          />
        </swiper-slide>
        <div class="swiper-button-prev" slot="button-prev"></div>
        <div class="swiper-button-next" slot="button-next"></div>
      </swiper>
    </div>
  </div>
</template>

<script>
import PostPreview from "./PostPreview.vue";
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import "swiper/css/swiper.css";

import { mapState, mapActions } from "vuex";

import formatDate from "@/utils/formatDate";

export default {
  name: "LatestPosts",
  mixins: [formatDate],
  components: { PostPreview, Swiper, SwiperSlide },
  data: () => {
    return {
      swiperPostsOption: {
        speed: 1200,
        slidesPerView: 1,
        slidesPerGroup: 1,
        loop: true,
        loopFillGroupWithBlank: false,
        pagination: {
          clickable: true,
        },
        autoplay: {
          delay: 10000,
          disableOnInteraction: false,
        },
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
        breakpoints: {
          1440: {
            slidesPerView: 3,
          },
          768: {
            slidesPerView: 2,
          },
          640: {
            slidesPerView: 1,
          },
        },
      },
    };
  },
  watch: {
    language(value) {
      this.getPosts({ language: value, limit: 6 });
      console.log(value);
    },
  },
  computed: {
    ...mapState("Blog", {
      posts: (state) => state.posts,
      language: (state) => state.language,
    }),
  },
  methods: {
    ...mapActions("Blog", ["getPosts"]),
  },
  created() {
    this.getPosts({ language: this.language, limit: 6 });
  },
};
</script>

<style lang="scss" scoped>
.latest-posts {
  width: 100%;
  background: #fff;
  box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.1);
  margin-bottom: 0;
  border-radius: 10px;
  padding-top: 2rem;
  padding-bottom: 1rem;

  .section-title {
    font-size: var(--text-28);
    font-weight: var(--text-semibold);
    text-align: center;
  }

  .posts {
    width: 100%;
    margin: 2rem 0;
    display: flex;
    justify-content: center;

    .swiper {
      max-width: 100%;
    }

    .slide-item {
      padding: 0 2rem;
      max-width: 100%;
      align-self: center;
      justify-self: center;
    }
  }
}

@media screen and (min-width: 1200px) {
  .latest-posts {
    .section-title {
      font-size: var(--text-40);
    }
  }
}
</style>