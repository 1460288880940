<template>
  <div class="promotional">
    <SectionTitle
      :title="$t('metrics.demo.title')"
      :subtitle="$t('metrics.demo.subtitle')"
    />
    <div class="text" data-aos="fade-up">
      <p>
        {{ $t("metrics.demo.text") }}
      </p>
    </div>
    <form @submit.prevent="handleSubmit" class="demo-form" data-aos="fade-up">
      <div class="input-wrapper">
        <v-text-field
          outlined
          color="#3487ac"
          class="name"
          :label="$t('metrics.demo.name')"
          type="text"
          name="user[name]"
          v-model="name"
          required
        >
        </v-text-field>
        <v-text-field
          outlined
          color="#3487ac"
          class="mobile"
          :label="$t('metrics.demo.mobile')"
          type="mobile"
          name="user[mobile]"
          v-model="mobile"
        >
        </v-text-field>
        <v-text-field
          outlined
          color="#3487ac"
          class="email"
          :label="$t('metrics.demo.email')"
          type="email"
          name="user[email]"
          v-model="email"
          required
        >
        </v-text-field>
        <v-text-field
          outlined
          color="#3487ac"
          class="company"
          :label="$t('metrics.demo.company')"
          type="text"
          name="user[company]"
          v-model="company"
          required
        >
        </v-text-field>
        <v-btn
          :loading="loading"
          @click="validate"
          type="submit"
          class="access-btn"
        >
          <img src="@/assets/img/icons/check.svg" />
          {{ $t("metrics.demo.btn") }}
        </v-btn>
        <v-alert
          class="alert-email"
          type="error"
          color="orange"
          outlined
          dense
          v-if="!validated"
        >
          {{ $t("layout.static.alert") }}
        </v-alert>
        <AppSnackbar v-model="snackbar" :show="message" :message="message" />
      </div>
    </form>
  </div>
</template>

<script>
import SectionTitle from "@/components/SectionTitle";
import AppSnackbar from "@/components/elements/AppSnackbar";
import { mapState, mapActions } from "vuex";

import { validateEmail } from "@/helpers/validateEmail";

export default {
  name: "AccessDemoForm",
  components: {
    SectionTitle,
    AppSnackbar,
  },
  data() {
    return {
      name: null,
      email: null,
      mobile: null,
      company: null,
      snackbar: false,
      loading: false,
      loader: null,
      alert: false,
    };
  },
  watch: {
    loader() {
      const l = this.loader;
      this[l] = !this[l];

      setTimeout(() => (this[l] = false), 3000);

      this.loader = null;
    },
  },
  computed: {
    ...mapState("LeadSignUp", {
      success: (state) => state.success,
      message: (state) => state.message,
    }),
    validated() {
      return !validateEmail(this.email);
    },
  },
  methods: {
    ...mapActions("LeadSignUp", ["submitLead"]),
    handleSubmit() {
      const payload = {
        name: this.name,
        email: this.email,
        mobile: this.mobile,
        company: this.company,
      };
      this.submitLead(payload);
      this.resetForm();
    },
    resetForm() {
      this.name = null;
      this.email = null;
      this.mobile = null;
      this.company = null;
    },
    validate() {
      if (this.name && this.email && this.company) {
        this.snackbar = true;
        this.loader = "loading";
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.promotional {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  .text {
    max-width: 600px;
    text-align: center;
    line-height: 1.7;

    p {
      font-size: var(--text-16);
      color: var(--gray-20);
    }
  }
  .demo-form {
    display: flex;
    margin: 0 auto;
    padding-top: 30px;
    flex-direction: column;

    .call {
      color: var(--gray-20);
      font-weight: var(--text-medium);
      margin-bottom: 3px;
    }

    .input-wrapper {
      width: 100%;
      display: flex;
      gap: 0.5rem;
      position: relative;

      .access-btn {
        width: fit-content;
        height: 55px;
        padding: 0 20px;
        border-radius: 5px;
        background: var(--blue-50);
        font-size: var(--text-14);
        font-weight: var(--text-semibold);
        text-transform: uppercase;
        color: var(--white);
        display: flex;
        align-items: center;
        justify-content: center;
        justify-self: center;
        gap: 1rem;

        &:hover {
          background: var(--blue-100);
        }
      }

      .alert-email {
        position: absolute;
        bottom: -40px;
      }
    }
  }
}

@media screen and (max-width: 1200px) {
  .promotional {
    .text {
      width: 80%;
    }
  }
}

@media screen and (max-width: 900px) {
  .promotional {
    padding-bottom: 50px;
    .text {
      p {
        font-size: var(--text-16);
      }
    }

    .demo-form {
      .input-wrapper {
        flex-direction: column;
        align-items: center;
        gap: 0.5rem;

        .access-btn {
          height: 50px;
          font-size: var(--text-16);
        }

        .alert-email {
        bottom: -100px;
        left: -10px;
        right: -10px;
      }
      }
    }
  }
}
</style>
