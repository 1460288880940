<template>
  <div class="home">
    <div class="carousel-container">
      <swiper class="swiper" :options="swiperOption">
        <div class="parallax-bg" slot="parallax-bg" data-swiper-parallax="-23%">
          <vue-particles
            color="#ccc"
            :particleOpacity="0.7"
            :particlesNumber="40"
            shapeType="circle"
            :particleSize="4"
            linesColor="#ccc"
            :linesWidth="1"
            :lineLinked="true"
            :lineOpacity="0.4"
            :linesDistance="250"
            :moveSpeed="3"
            :hoverEffect="true"
            hoverMode="grab"
            :clickEffect="true"
            clickMode="push"
          >
          </vue-particles>
        </div>
        <swiper-slide>
          <TheCarousel
            class="carousel-item"
            :title="$t('home.carousel.metrics.heroTitle')"
            :subtitle="$t('home.carousel.metrics.heroSubtitle')"
            :firstParagraph="$t('home.carousel.metrics.texts.firstParagraph')"
            :secondParagraph="$t('home.carousel.metrics.texts.secondParagraph')"
            :img="
              require(`@/assets/img/metricas-de-audiencia-principal-min.png`)
            "
          />
        </swiper-slide>
        <swiper-slide>
          <TheCarousel
            class="carousel-item"
            :title="$t('home.carousel.smartCity.heroTitle')"
            :subtitle="$t('home.carousel.smartCity.heroSubtitle')"
            :firstParagraph="$t('home.carousel.smartCity.texts.firstParagraph')"
            :img="require(`@/assets/img/smart-city.png`)"
          />
        </swiper-slide>
        <swiper-slide>
          <TheCarousel
            v-if="posts.length"
            class="carousel-item"
            :title="posts[0].title"
            subtitle="Última do Blog Datavisiooh"
            :firstParagraph="postBody"
            :img="posts[0].image"
          />
        </swiper-slide>
        <div class="swiper-pagination" slot="pagination"></div>
        <div class="swiper-button-prev swiper-button" slot="button-prev"></div>
        <div class="swiper-button-next swiper-button" slot="button-next"></div>
      </swiper>
    </div>

    <div class="solutions-container">
      <SectionTitle
        :title="$t('home.solutions.title')"
        :subtitle="$t('home.solutions.subtitle')"
      />
      <div class="solution-cards">
        <SolutionCard
          class="solution-card"
          v-for="(solutionCard, i) in solutionCards"
          :key="i"
          :cardTitle="$t(solutionCard.title)"
          :cardText="$t(solutionCard.text)"
          :cardCTA="$t(solutionCard.CTAText)"
          :cardImg="solutionCard.cardImg"
        >
          <template v-slot:panel>
            <v-expansion-panels>
              <SolutionPanel
                v-for="(panel, index) in solutionCard.panel"
                :key="index"
                :panel="{ title: $t(panel.title), content: $t(panel.content) }"
              />
            </v-expansion-panels>
          </template>
          <template v-slot:cta-button>
            <AppButton
              buttonClass="panel-btn"
              :buttonLink="solutionCard.link"
              :buttonText="$t('home.solutions.metricsCard.btn')"
            />
          </template>
        </SolutionCard>
      </div>
    </div>
    <ClientsSection />
    <div class="latest-posts">
      <LatestPosts />
    </div>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import "swiper/css/swiper.css";
import TheCarousel from "@/components/TheCarousel";
import SectionTitle from "@/components/SectionTitle";
import SolutionCard from "@/components/elements/SolutionCard";
import SolutionPanel from "@/components/elements/SolutionPanel";
import ClientsSection from "@/components/ClientsSection";
import AppButton from "@/components/elements/AppButton";
import LatestPosts from "@/components/LatestPosts";

import { mapState, mapActions } from "vuex";

export default {
  name: "Home",
  components: {
    Swiper,
    SwiperSlide,
    TheCarousel,
    SectionTitle,
    SolutionCard,
    SolutionPanel,
    ClientsSection,
    AppButton,
    LatestPosts,
  },
  data() {
    return {
      swiperOption: {
        speed: 800,
        parallax: true,
        autoplay: {
          delay: 15000,
          disableOnInteraction: false,
        },

        loop: true,
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
      },
      solutionCards: {
        metricsCard: {
          title: "home.solutions.metricsCard.title",
          text: "home.solutions.metricsCard.text",
          CTAText: "home.solutions.metricsCard.cta",
          cardImg: "metricas-inicial-min.png",
          panel: [
            {
              title: "home.solutions.metricsCard.panels.realAudience.title",
              content: "home.solutions.metricsCard.panels.realAudience.text",
            },
            {
              title: "home.solutions.metricsCard.panels.impacts.title",
              content: "home.solutions.metricsCard.panels.impacts.text",
            },
            {
              title: "home.solutions.metricsCard.panels.statistics.title",
              content: "home.solutions.metricsCard.panels.statistics.text",
            },
            {
              title: "home.solutions.metricsCard.panels.integration.title",
              content: "home.solutions.metricsCard.panels.integration.text",
            },
            {
              title: "home.solutions.metricsCard.panels.export.title",
              content: "home.solutions.metricsCard.panels.export.text",
            },
          ],
          link: "metrics",
        },
        smartCityCard: {
          title: "home.solutions.smartCityCard.title",
          text: "home.solutions.smartCityCard.text",
          CTAText: "home.solutions.smartCityCard.cta",
          cardImg: "smart-city-inicial-min.png",
          panel: [
            {
              title:
                "home.solutions.smartCityCard.panels.trafficManagement.title",
              content:
                "home.solutions.smartCityCard.panels.trafficManagement.text",
            },
            {
              title: "home.solutions.smartCityCard.panels.monitoring.title",
              content: "home.solutions.smartCityCard.panels.monitoring.text",
            },
            {
              title: "home.solutions.smartCityCard.panels.flow.title",
              content: "home.solutions.smartCityCard.panels.flow.text",
            },
            {
              title:
                "home.solutions.smartCityCard.panels.customSolutions.title",
              content:
                "home.solutions.smartCityCard.panels.customSolutions.text",
            },
          ],
          link: "smart-city",
        },
      },
    };
  },
  watch: {
    language(value) {
      this.getPosts({ language: value, limit: 1 });
    },
  },
  computed: {
    ...mapState("Blog", {
      posts: (state) => state.posts,
      language: (state) => state.language,
    }),
    postBody() {
      return (
        this.posts[0].body.replace(/(<([^>]+)>)/gi, "").substring(0, 340) +
        "..."
      );
    },
  },
  methods: {
    ...mapActions("Blog", ["getPosts"]),
  },
  created() {
    this.getPosts({ language: this.language, limit: 1 });
  },
};
</script>

<style lang="scss" scoped>
.home {
  width: 97%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
  .carousel-container {
    width: 100%;

    .swiper {
      width: 100%;
      border-radius: 10px;
      box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.1);
      margin-bottom: -5px;

      .parallax-bg {
        position: absolute;
        left: 0;
        top: 0;
        width: 130%;
        height: 100%;
        background-size: cover;
        background-position: left;
        background: var(--white);
        background-image: linear-gradient(120deg, #fefefe 60%, #efefef 100%);
      }

      .swiper-slide {
        padding: 2rem 4rem;
      }
    }
  }

  .solutions-container {
    width: 100%;
    background: var(--white);
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    padding: 0.5rem 3rem;

    .solution-cards {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 2rem;
      .solution-card {
        background: var(--white);
        background: #fff
          url("data:image/svg+xml,%3Csvg width='1280' height='710' xmlns='http://www.w3.org/2000/svg'%3E %3Cg fill='%233487ac09' fill-rule='evenodd'%3E %3Cpath d='M0 63.354c47.677-2.931 102.14 12.343 163.387 45.822 91.87 50.22 126.954 158.927 248.81 226.016 121.856 67.09 217.644 28.645 324.582 86.582C843.72 479.71 882.997 594 979.392 646.458c64.263 34.971 130.87 56.185 199.821 63.641H0V63.354z'/%3E %3Cpath d='M0 1.393C50.612-3.2 109.835 13.082 177.667 50.234c101.749 55.73 130.439 163.5 265.397 237.95 134.958 74.45 204.648 26.67 323.085 90.963 118.436 64.293 142.832 176.85 249.59 235.062 71.173 38.808 167.927 70.771 290.261 95.89H0V1.393z' opacity='.7'/%3E %3C/g%3E %3C/svg%3E")
          left bottom/100% no-repeat fixed;

        border-radius: 10px;
        padding: 3rem 3rem;
        gap: 2rem;

        &:nth-child(1) {
          border-bottom: 1px solid rgba(149, 149, 149, 0.1);
        }

        .panel-btn {
          padding: 10px;
          font-size: var(--text-16);
        }
      }
    }
  }

  .latest-posts {
    max-width: 100% !important;
  }
}

@media screen and (max-width: 1400px) {
  .swiper {
    .swiper-slide {
      padding-bottom: 0;
    }
  }
}

@media screen and (max-width: 1100px) {
  .home {
    .swiper {
      .swiper-slide {
        padding: 0 2rem;
      }
    }

    .solutions-container {
      padding: 0.5rem 0;
    }
  }
}

@media screen and (max-width: 640px) {
  .home {
    .swiper {
      padding: 0;
    }

    .solutions-container {
      .solution-cards {
        .solution-card {
          padding: 1rem;
        }
      }
    }
  }
}
</style>
