<template>
  <div class="planning-card" :class="cardClass">
    <div class="text-info" data-aos="fade-up">
      <div class="card-title-wrapper">
        <img class="icon" :src="require(`@/assets/img/icons/${icon}`)" />
        <h3 class="card-title">{{ title }}</h3>
      </div>
      <p class="card-text">{{ firstParagraph }}</p>
      <p class="card-text">{{ secondParagraph }}</p>
    </div>
    <div class="img-wrapper" data-aos="fade-up">
      <img
        class="card-img"
        :src="require(`@/assets/img/${img}`)"
        :alt="title"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: "PlanningCard",
  props: {
    icon: String,
    title: String,
    firstParagraph: String,
    secondParagraph: String,
    img: String,
    cardClass: String,
  },
};
</script>

<style lang="scss" scoped>
.planning-card {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 2rem;
  margin-bottom: 4rem;
  padding: 1rem 7rem;

  .text-info {
    max-width: 700px;

    .icon {
      width: 40px;
    }

    .card-title-wrapper {
      .card-title {
        font-size: var(--text-22);
        font-weight: var(--text-semibold);
        padding-bottom: 1.5rem;
        margin-bottom: 20px;
        position: relative;

        &::before {
          content: "";
          width: 40px;
          height: 5px;
          background: var(--blue-50);
          position: absolute;
          bottom: 0;
        }
      }
    }

    .card-text {
      font-size: var(--text-18);
      line-height: 1.8;
    }
  }

  .img-wrapper {
    display: flex;
    align-items: center;

    .card-img {
      float: right;
      width: 100%;
      max-width: 700px;
      box-shadow: 0 0 20px 10px rgba(0, 0, 0, 0.1);
      border-radius: 5px;
    }
  }
}

.invert {
  flex-direction: row-reverse;
}

@media screen and (max-width: 1300px) {
  .planning-card {
    flex-direction: column;
    padding: 50px 5rem;

    .text-info {
      .card-text {
        max-width: 100%;
      }
    }
  }
}

@media screen and (max-width: 900px) {
  .planning-card {
    padding: 30px 3rem;

    .text-info {
      .card-title-wrapper {
        .card-title {
          font-size: var(--text-20);
        }
      }

      .card-text {
        font-size: var(--text-16);
        line-height: 26px;
        max-width: 600px;
      }
    }
  }

  .invert {
    .text-info {
      grid-column: 1;
    }
  }
}

@media screen and (max-width: 640px) {
  .planning-card {
    padding: 50px 1rem;
  }
}
</style>
