<template>
  <router-link :to="buttonLink" class="app-button" :class="buttonClass">
    {{ buttonText }}
    <img :src="buttonIcon" :alt="buttonIcon" :style="iconStyle" />
  </router-link>
</template>

<script>
export default {
  name: "AppButton",
  props: {
    buttonLink: String,
    buttonText: String,
    buttonIcon: String,
    iconStyle: String,
    buttonClass: String,
  },
};
</script>

<style scoped lang="scss">
.app-button {
  width: fit-content;
  border: none;
  border-radius: 5px;
  background: var(--blue-50);
  color: var(--white);
  font-size: var(--text-14) !important;
  font-weight: var(--text-medium);
  letter-spacing: 0.5px;
  text-align: center;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: space-around;
  cursor: pointer;
  transition: background 0.1s ease-in;

  &:hover {
    background: var(--blue-100);
  }
}
</style>
