<template>
  <v-expansion-panel>
    <v-expansion-panel-header class="panel-title">
      {{ panel.title }}
      <template v-slot:actions>
        <v-icon color="#3487ac"> $expand </v-icon>
      </template>
    </v-expansion-panel-header>
    <v-expansion-panel-content class="panel-content">
      {{ panel.content }}
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
export default {
  name: "SolutionPanel",
  props: {
    panel: {
      type: Object,
    },
  },
};
</script>

<style lang="scss" scoped>
.panel-title {
  color: var(--gray-80);
  font-size: var(--text-18);
  font-weight: var(--text-semibold);
}

.panel-content {
  color: var(--gray-20);
  font-size: var(--text-16);
}

@media screen and (max-width: 1340px) {
  .panel-title {
    font-size: var(--text-16);
  }

  .panel-content {
    font-size: var(--text-14);
  }
}
</style>
