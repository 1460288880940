<template>
  <div class="footer">
    <div class="newsletter-container">
      <div class="newsletter-wrapper">
        <h4 class="cta">{{ $t("layout.footer.newsletter.cta") }}</h4>
        <NewsletterForm
          :nameLabel="$t('layout.footer.newsletter.name')"
          :emailLabel="$t('layout.footer.newsletter.email')"
          inputColor="#fff"
          :buttonText="$t('layout.footer.newsletter.btn')"
          buttonHeight="39"
          buttonColor="#316d88"
          :dense="true"
        />
      </div>
    </div>
    <div class="main-container">
      <div class="main-wrapper">
        <div class="logo-social">
          <router-link to="/">
            <img src="../assets/img/logo.svg" alt="Datavisiooh" class="logo" />
          </router-link>
          <ul class="social-links">
            <li v-for="(item, i) in socialLinks" :key="i">
              <a :href="item.link" target="_blank"
                ><img
                  :src="require(`@/assets/img/icons/${item.icon}`)"
                  :alt="item.icon"
              /></a>
            </li>
          </ul>
        </div>
        <div class="menu">
          <ul class="menu-links">
            <li class="menu-link" v-for="(item, i) in items" :key="i">
              <router-link :to="item.link">{{ $t(item.page) }}</router-link>
            </li>
            <li class="menu-link whatsapp">
              <img
                class="whatsapp-icon"
                src="@/assets/img/icons/whatsapp.svg"
                alt="WhatsApp"
              />
              +55 41 98882-5882
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="certificates-container">
      <img
        class="certificate nvidia"
        src="../assets/img/nvidia-program.png"
        alt="Nvidia Inception Program"
      />
      <img
        class="certificate"
        src="../assets/img/ssl-secure.png"
        alt="SSL Secure Connection"
      />
      <img
        class="certificate"
        src="../assets/img/google-safe-browsing.png"
        alt="Google Safe Browsing"
      />
    </div>
  </div>
</template>

<script>
import NewsletterForm from "@/components/NewsletterForm";
import { mapState, mapActions } from "vuex";

export default {
  components: { NewsletterForm },
  data() {
    return {
      name: null,
      email: null,
      snackbar: false,
      loading: false,
      loader: null,
      socialLinks: [
        {
          icon: "youtube.svg",
          link: "https://www.youtube.com/channel/UCYwSVZouUVXSo2k9FQ4E51Q",
        },
        {
          icon: "facebook.svg",
          link: "https://www.facebook.com/Datavisiooh/",
        },
        {
          icon: "instagram.svg",
          link: "https://www.instagram.com/datavisiooh/",
        },
        {
          icon: "linkedin.svg",
          link: "https://www.linkedin.com/company/datavisiooh/",
        },
      ],
      items: [
        {
          link: "metrics",
          page: "layout.footer.menu.metrics",
        },
        {
          link: "faq",
          page: "layout.footer.menu.faq",
        },
        {
          link: "contact",
          page: "layout.footer.menu.contact",
        },
        {
          link: "privacy-policy",
          page: "layout.footer.menu.privacyPolicy",
        },
        {
          link: "smart-city",
          page: "layout.footer.menu.smartCity",
        },
      ],
    };
  },
  watch: {
    loader() {
      const l = this.loader;
      this[l] = !this[l];

      setTimeout(() => (this[l] = false), 3000);

      this.loader = null;
    },
  },
  computed: {
    ...mapState("Newsletter", {
      success: (state) => state.success,
      message: (state) => state.message,
    }),
  },
  methods: {
    ...mapActions("Newsletter", ["submitForm"]),
    handleFormSubmit() {
      const payload = {
        name: this.name,
        email: this.email,
      };
      this.submitForm(payload);
      this.resetForm();
    },
    resetForm() {
      this.name = null;
      this.email = null;
    },
    validate() {
      if (this.name && this.email) {
        this.snackbar = true;
        this.loader = "loading";
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.footer {
  width: 100%;
  .newsletter-container {
    width: 100%;
    background-color: #53b5e0;
    display: flex;
    justify-content: center;
    padding: 1rem 1rem 0;

    .newsletter-wrapper {
      display: flex;
      gap: 4rem;
      max-width: 1100px;
    }

    .cta {
      font-weight: 600;
      font-size: 18px;
      color: #fff;
      margin-top: 10px;
    }
  }

  .main-container {
    width: 100%;
    background-color: #316d88;
    display: flex;
    justify-content: center;

    .main-wrapper {
      width: 100%;
      max-width: 980px;
      display: flex;
      justify-content: space-between;
      padding: 2rem 0 1rem;

      .logo-social {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 1rem 0;
        .logo {
          max-width: 100%;
          width: 260px;
        }

        .social-links {
          display: flex;
          gap: 2.15rem;
          padding: 0;
          max-width: 100%;

          li {
            list-style: none;

            a {
              img {
                max-width: 40px;
                max-height: 40px;
                width: 100%;
                transition: 0.1s;

                &:hover {
                  transform: translateY(1px);
                }
              }
            }
          }
        }
      }

      .menu {
        .menu-links {
          display: grid;
          grid-template-columns: 1.6fr 1fr 1fr;
          gap: 2.5rem 0;
          list-style: none;

          .menu-link {
            a {
              color: #fff;
              text-decoration: none;
              font-size: 16px;

              &:hover {
                opacity: 0.8;
              }
            }
          }

          .whatsapp {
            color: #fff;
            font-size: 16px;

            .whatsapp-icon {
              width: 24px;
              vertical-align: middle;
              padding-right: 3px;
            }
          }
        }
      }
    }
  }

  .certificates-container {
    background-color: #fff;
    display: flex;
    justify-content: center;
    gap: 3rem;
    padding: 0.2rem;

    .certificate {
      width: 100%;
      max-width: 140px;
    }

    .nvidia {
      max-width: 180px;
    }
  }
}

@media screen and (max-width: 1020px) {
  .footer {
    .newsletter-container {
      .newsletter-wrapper {
        flex-direction: column;
        gap: 0.5rem;

        .cta {
          margin-top: 0;
        }
      }
    }

    .main-container {
      .main-wrapper {
        justify-content: center;
        gap: 2rem;
        .menu {
          .menu-links {
            grid-template-columns: 1.3fr 1fr;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 700px) {
  .footer {
    .main-container {
      .main-wrapper {
        flex-direction: column;
        align-items: center;

        .menu {
          padding-top: 0;
          .menu-links {
            grid-template-columns: 1fr;
            gap: 1.5rem;
          }
        }
      }
    }

    .certificates-container {
      flex-wrap: wrap;
      .certificate {
        max-width: 80px;
      }
    }
  }
}

@media screen and (max-width: 460px) {
  .footer {
    .main-container {
      .main-wrapper {
        .logo-social {
          .social-links {
            flex-wrap: wrap;
            justify-content: center;
          }
        }
      }
    }
  }
}
</style>