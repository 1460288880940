<template>
  <div class="wrapper">
    <header>
      <div class="container d-lg-flex">
        <div class="logo-menu-container">
          <router-link to="/" @click.native="scrollToTop"
            ><img
              class="logo d-none d-sm-block"
              src="../assets/img/logo.png"
              alt="Datavisiooh"
            />
            <img
              class="logo-mobile d-sm-none d-block"
              src="../assets/img/logo-mobile.png"
              alt="Datavisiooh"
            />
          </router-link>
          <div class="d-lg-none">
            <button @click.stop="drawer = !drawer">
              <img
                v-if="!drawer"
                src="@/assets/img/icons/menu-line.svg"
                alt="Menu"
              />
              <img
                v-if="drawer"
                src="@/assets/img/icons/close-line.svg"
                alt="Close"
              />
            </button>
          </div>
        </div>
        <ul class="nav-links d-none d-lg-flex">
          <li class="nav-link" v-for="(item, i) in items" :key="i">
            <router-link :to="item.link">{{ $t(item.page) }}</router-link>
          </li>
          <li class="nav-link">
            <a
              href="https://dash.datavisiooh.com"
              target="_blank"
              class="access-dashboard-btn"
            >
              {{ $t("layout.navbar.dashboard") }}
            </a>
          </li>
          <li class="nav-link">
            <a
              href="https://blog.datavisiooh.com"
              target="_blank"
              class="access-dashboard-btn"
            >
              Blog
            </a>
          </li>

          <li class="language-select">
            <button
              class="btn"
              @click="
                setLanguage('pt');
                $i18n.locale = 'pt';
              "
              title="Português"
            >
              <img src="@/assets/img/icons/br.svg" alt="Português" />
            </button>
            <button
              class="btn"
              @click="
                setLanguage('es');
                $i18n.locale = 'es';
              "
              title="Español"
            >
              <img src="@/assets/img/icons/es.svg" alt="Espanhol" />
            </button>
          </li>
        </ul>
        <v-navigation-drawer
          v-model="drawer"
          fixed
          temporary
          right
          hide-overlay
          height="40%"
          class="d-block d-lg-none"
        >
          <ul class="nav-links">
            <li class="nav-link" v-for="item in items" :key="item.page">
              <router-link :to="item.link">{{ $t(item.page) }}</router-link>
            </li>
            <li class="nav-link">
              <a
                href="https://dash.datavisiooh.com"
                target="_blank"
                class="access-dashboard-btn"
              >
                {{ $t("layout.navbar.dashboard") }}
              </a>
            </li>
            <li class="nav-link">
              <a
                href="https://blog.datavisiooh.com"
                target="_blank"
                class="access-dashboard-btn"
              >
                Blog
              </a>
            </li>

            <li class="language-select">
              <button
                class="btn"
                @click="
                  setLanguage('pt');
                  $i18n.locale = 'pt';
                "
                title="Português"
              >
                <img src="@/assets/img/icons/br.svg" alt="Português" />
              </button>
              <button
                class="btn"
                @click="
                  setLanguage('es');
                  $i18n.locale = 'es';
                "
                title="Español"
              >
                <img src="@/assets/img/icons/es.svg" alt="Espanhol" />
              </button>
            </li>
          </ul>
        </v-navigation-drawer>
      </div>
    </header>
  </div>
</template>

<script>
import { mapMutations } from "vuex";

export default {
  name: "Header",
  components: {},
  data: () => {
    return {
      drawer: null,
      items: [
        {
          link: "metrics",
          page: "layout.navbar.metrics",
        },
        {
          link: "smart-city",
          page: "layout.navbar.smartCity",
        },
        {
          link: "faq",
          page: "layout.navbar.faq",
        },
        {
          link: "contact",
          page: "layout.navbar.contact",
        },
      ],
    };
  },
  methods: {
    scroll() {
      window.addEventListener("scroll", () => {
        const container = document.querySelector(".container");
        if (window.scrollY === 0) {
          container.classList.remove("is-scrolling");
        } else {
          container.classList.add("is-scrolling");
        }
      });
    },
    scrollToTop() {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    },
    ...mapMutations("Blog", {
      setLanguage: "SET_LANGUAGE",
    }),
  },
  mounted() {
    this.scroll();
  },
  created() {
    this.scroll();
  },
};
</script>

<style lang="scss" scoped>
.wrapper {
  position: sticky;
  top: 0;
  z-index: 98;
  width: 100%;

  .container {
    width: 100%;
    justify-content: space-between;
    align-items: center;
    padding: 0.5rem 5rem;
    background: var(--white);
    transition: all 0.5s ease-in-out;
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.1);
    border-radius: 10px;

    .logo-menu-container {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .logo {
        width: 100%;
        max-width: 270px;
        height: 60px;
      }

      .logo-mobile {
        max-width: 50px;
      }
    }

    .nav-links {
      transition: all 1s;
      border-radius: 0 0 0 5px;

      .nav-link {
        padding: 1rem 1.2rem;

        a {
          color: var(--gray-80);
          font-size: var(--text-14);
          font-weight: var(--text-bold);
          text-transform: uppercase;
          position: relative;
          transition: 0.3s;

          &:hover {
            color: var(--blue-50);
          }

          &::after {
            content: "";
            position: absolute;
            background: var(--blue-50);
            height: 3px;
            width: 0;
            bottom: -3px;
            left: 0;
            transition: 0.3s;
          }

          &:hover::after {
            width: 100%;
          }
        }

        .router-link-active {
          border-bottom: 3px solid var(--blue-50);
        }
      }

      .language-select {
        margin-left: 2rem;
        align-self: center;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 0.5rem;
        width: 30px;
        height: 30px;

        .btn {
          img {
            width: 20px;
            box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);
            border-radius: 50%;
            transition: 0.3s ease;

            &:hover {
              transform: translateY(-2px);
            }
          }
        }
      }
    }

    .show-menu {
      display: block;
    }

    .hide-menu {
      display: none;
    }
  }

  .is-scrolling {
    padding-top: 0;
    padding-bottom: 0;
    box-shadow: 0 2px 4px rgb(0 0 0 / 20%);
  }
}

@media screen and (max-width: 1450px) {
  .wrapper {
    .container {
      .logo {
        max-width: 250px;
      }
    }
  }
}

@media screen and (max-width: 1264px) {
  .nav-links {
    padding-top: 2rem;
  }
}

@media screen and (max-width: 740px) {
  .wrapper {
    .container {
      padding: 10px;
    }
  }
}

@media screen and (max-width: 600px) {
  .wrapper {
    .container {
      padding: 10px 40px;
    }
  }
}
</style>
