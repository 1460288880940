<template>
  <div class="post-preview">
    <a :href="link" target="_blank">
      <img class="image" :src="image" :alt="title" />
      <div class="post-info">
        <p class="date">{{ date }}</p>
        <p class="category">{{ category }}</p>
      </div>
      <h3 class="name">{{ title }}</h3>
      <p class="description" v-html="description"></p>
    </a>
  </div>
</template>

<script>
export default {
  name: "PostPreview",
  props: {
    image: {
      type: String,
      default: null,
    },
    date: {
      type: String,
      default: null,
    },
    category: {
      type: String,
      default: null,
    },
    title: {
      type: String,
      default: null,
    },
    description: {
      type: String,
      default: null,
    },
    link: {
      type: String,
      default: null,
    },
  },
};
</script>

<style lang="scss" scoped>
.post-preview {
  width: 100%;
  padding-bottom: 1rem;
  border-radius: 8px;

  .image {
    width: 100%;
  }

  .post-info {
    display: flex;
    justify-content: space-between;
    margin-top: 5px;
    padding: 0 5px;

    .date {
      color: #bdbdbd;
    }

    .category {
      font-weight: var(--text-semibold);
    }
  }

  .name {
    font-size: var(--text-22);
    font-weight: var(--text-bold);
    line-height: 140%;
    padding-left: 2px;
    margin-bottom: 1rem;
    padding: 1rem 5px 0;
  }

  .description {
    line-height: 150%;
    font-size: var(--text-16);
    padding: 0 5px;
  }

  &:hover {
    box-shadow: 0px 0px 12px 0 rgba(0, 0, 0, 0.1);
  }
}

@media screen and (min-width: 1290px) {
  .post-preview {
    border-bottom: none;
    .name {
      font-size: var(--text-26);
    }
  }
}
</style>