import { newsletter } from "../../../api/newsletter.api";
import i18n from '../../../i18n'

export const Newsletter = {
  state: {
    newsletter: {
      name: null,
      email: null,
    },
    success: false,
    message: null,
  },

  mutations: {
    SET_FORM_DATA(state, { name, email }) {
      state.newsletter.name = name;
      state.newsletter.email = email;
    },
  },

  actions: {
    async submitForm({ state, commit }, payload) {
      try {
        const { data } = await newsletter(payload);
        state.message = i18n.t("smartCity.newsletter.response");
        state.success = data.success;
        commit("SET_FORM_DATA", data);
        setTimeout(function() {
          state.message = null;
        }, 7000);
      } catch (error) {
        console.log(error);
      }
    },
  },
  getters: {},
  namespaced: true,
};
