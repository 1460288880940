<template>
  <div class="about-card">
    <div class="card" data-aos="fade-up">
      <div class="title-wrapper">
        <img
          class="icon"
          :src="require(`@/assets/img/icons/${icon}`)"
          :alt="icon"
        />
        <h4 class="card-title">{{ title }}</h4>
      </div>
      <div class="text-content" data-aos="fade-up">
        <p class="card-text">
          {{ firstParagraph }}
        </p>
        <p class="card-text">
          {{ secondParagraph }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AboutCard",
  props: {
    icon: String,
    title: String,
    firstParagraph: String,
    secondParagraph: String,
  },
};
</script>

<style scoped lang="scss">
.about-card {
  .title-wrapper {
    .icon {
      width: 40px;
    }

    .card-title {
      font-size: var(--text-22);
      font-weight: var(--text-semibold);
      margin-bottom: 20px;
      position: relative;
      padding: 0 0 1.5rem;

      &::before {
        content: "";
        width: 40px;
        height: 5px;
        background: var(--blue-50);
        position: absolute;
        bottom: 0;
      }
    }
  }

  .text-content {
    .card-text {
      font-size: var(--text-16);
      line-height: 1.7;
    }
    &:nth-child(even) {
      padding-bottom: 2px;
    }
  }
}

@media screen and (max-width: 1200px) {
  .about-card {
    .title-wrapper {
      .card-title {
        font-size: var(--text-20);
      }
    }

    .text-content {
      .card-text {
        font-size: var(--text-16);
      }
    }
  }
}

@media screen and (max-width: 900px) {
  .about-card {
    .title-wrapper {
      .card-title {
        margin-bottom: 0;
      }
    }

    &:last-child {
      padding-bottom: 0;
    }
  }
}
</style>
