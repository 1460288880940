<template>
  <div class="payment-card">
    <img
      :src="require(`@/assets/img/payment/${image}.png`)"
      :alt="title"
      class="image"
    />
    <h3 class="card-title">{{ title }}</h3>
    <p class="instruction">{{ instruction }}</p>
  </div>
</template>

<script>
export default {
  name: "PaymentInstructionCard",
  props: {
    image: {
      type: String,
      default: null,
    },
    title: {
      type: String,
      default: null,
    },
    instruction: {
      type: String,
      default: null,
    },
  },
};
</script>

<style lang="scss" scoped>
.payment-card {
  .card-title {
    font-size: var(--text-22);
    font-weight: var(--text-medium);
    padding-bottom: 5px;
  }

  .instruction {
    line-height: 160%;
  }
}
</style>