<template>
  <div class="layout">
    <PageLoader />
    <TheHeader />
    <router-view class="content" :key="$route.path" />
    <TheFooter class="footer" />
    <button @click="goToTop" class="go-to-top">
      <v-icon color="white">mdi-arrow-up</v-icon>
    </button>
  </div>
</template>

<script>
import PageLoader from "@/components/PageLoader";
import TheHeader from "@/components/TheHeader";
import TheFooter from "@/components/TheFooter";

export default {
  name: "Layout",
  components: {
    PageLoader,
    TheHeader,
    TheFooter,
  },
  data() {
    return {
      overlay: true,
    };
  },
  methods: {
    goToTop() {
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
    },
  },
  mounted() {
    setTimeout(() => {
      this.overlay = false;
    }, 3000);
  },
};
</script>

<style lang="scss">
.layout {
  background: #fbfbfb;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;

  .footer {
    margin-top: auto;
  }
}
.go-to-top {
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  position: fixed;
  bottom: 3rem;
  right: 2rem;
  background: var(--blue-50);
  box-shadow: -1px 1px 5px 1px rgba(0, 0, 0, 0.2);
  border-radius: 50%;

  &:hover {
    background: var(--blue-100);
  }
}
.fade-enter-active,
.fade-leave-active {
  transition: all 0.5s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
  transform: translateX(10px);
}

.content {
  padding-top: 1.9rem;
  max-width: 1900px;
  width: 97%;
}
</style>
