import { getPosts } from "../../../api/blog.api";

export const Blog = {
  state: {
    language: "pt",
    posts: [],
  },
  mutations: {
    SET_LANGUAGE(state, language) {
      localStorage.setItem("language", language);
      state.language = language;
    },
    SET_POSTS(state, { posts }) {
      state.posts = posts;
    },
  },
  actions: {
    async getPosts({ state, commit }, { limit }) {
      try {
        const {
          data: { posts },
        } = await getPosts({
          language: localStorage.getItem("language") || state.language,
          limit,
        });
        commit("SET_POSTS", { posts });
      } catch (e) {
        console.log(e);
      }
    },
  },
  namespaced: true,
};
