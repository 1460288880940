<template>
  <div class="contact">
    <SectionTitle :title="$t('contact.title')" class="title" />
    <p class="text" data-aos="fade-up">
      {{ $t("contact.text") }}
    </p>
    <div class="overlay"></div>
    <div class="contact-area" data-aos="fade-up">
      <div class="contact-ways">
        <div class="whatsapp">
          <h3 class="cta">WhatsApp</h3>
          <img
            class="img"
            src="@/assets/img/icons/contact-whatsapp.svg"
            alt="Whatsapp"
          />
          <a
            class="link"
            href="https://api.whatsapp.com/send?phone=5541988825882"
            target="_blank"
          >
            {{ $t("contact.sendMessage") }}
          </a>
        </div>
        <div class="phone">
          <h3 class="cta">{{ $t("contact.phone") }}</h3>
          <img class="img" src="@/assets/img/icons/phone.svg" alt="Whatsapp" />
          <a class="link" href="tel:+5541988825882" target="_blank"
            >+55 41 98882-5882</a
          >
        </div>
        <div class="email">
          <h3 class="cta">{{ $t("contact.email") }}</h3>
          <img class="img" src="@/assets/img/icons/email.svg" alt="Whatsapp" />
          <a class="link" href="mailto:info@datavisiooh.com" target="_blank"
            >info@datavisiooh.com</a
          >
        </div>
      </div>

      <form class="contact-form" @submit.prevent="handleFormSubmit">
        <v-text-field
          outlined
          color="#3487ac"
          class="name"
          :label="$t('contact.name')"
          type="text"
          name="contact[name]"
          v-model="name"
          required
        >
        </v-text-field>
        <v-text-field
          outlined
          color="#3487ac"
          class="email"
          :label="$t('contact.email') + '*'"
          type="email"
          name="contact[email]"
          v-model="email"
          required
        >
        </v-text-field>
        <v-text-field
          outlined
          color="#3487ac"
          class="company"
          :label="$t('contact.company')"
          type="text"
          name="contact[company]"
          v-model="company"
          required
        >
        </v-text-field>
        <v-textarea
          outlined
          color="#3487ac"
          class="message"
          :label="$t('contact.message')"
          name="contact[message]"
          rows="7"
          cols="35"
          v-model="userMessage"
          required
        ></v-textarea>
        <v-btn :loading="loading" @click="validate" type="submit" class="send">
          {{ $t("contact.btn") }}
        </v-btn>
        <v-alert
          class="alert-email"
          type="error"
          color="orange"
          outlined
          dense
          v-if="!validated"
        >
          {{ $t("layout.static.alert") }}
        </v-alert>
      </form>
    </div>
    <AppSnackbar v-model="snackbar" :show="message" :message="message" />
  </div>
</template>

<script>
import SectionTitle from "@/components/SectionTitle";
import AppSnackbar from "@/components/elements/AppSnackbar";
import { mapState, mapActions } from "vuex";

import { validateEmail } from "@/helpers/validateEmail";

export default {
  components: { SectionTitle, AppSnackbar },
  name: "Contact",
  data() {
    return {
      snackbar: false,
      name: null,
      email: null,
      company: null,
      userMessage: null,
      loading: false,
      loader: null,
    };
  },
  watch: {
    loader() {
      const l = this.loader;
      this[l] = !this[l];

      setTimeout(() => (this[l] = false), 3000);

      this.loader = null;
    },
  },
  computed: {
    ...mapState("Contact", {
      message: (state) => state.message,
      success: (state) => state.success,
    }),
    validated() {
      return !validateEmail(this.email);
    },
  },
  methods: {
    ...mapActions("Contact", ["submitForm"]),
    handleFormSubmit() {
      const payload = {
        name: this.name,
        email: this.email,
        company: this.company,
        message: this.userMessage,
      };
      this.submitForm(payload);
      this.resetForm();
    },
    resetForm() {
      this.name = null;
      this.email = null;
      this.company = null;
      this.userMessage = null;
    },
    validate() {
      if (this.name && this.email && this.userMessage && this.company) {
        this.snackbar = true;
        this.loader = "loading";
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.contact {
  border-radius: 10px;
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.1);
  margin: 2rem 0;
  padding: 2rem 12rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  background: url("../assets/img/atendimento.png") no-repeat;
  background-position: center;
  background-size: cover;

  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.4);
    border-radius: 10px;
  }

  .title {
    z-index: 2;

    filter: brightness(500%);
  }

  .text {
    max-width: 640px;
    text-align: center;
    margin-top: -10px;
    line-height: 1.7;
    color: var(--gray-20);
    color: #fff;
    z-index: 2;
  }

  .contact-area {
    display: flex;
    justify-content: center;
    gap: 2rem;
    padding: 0 3rem;
    max-width: 1200px;
    width: 100%;
    box-shadow: 0 0 10px 5px rgba(0, 0, 0, 0.1);
    background: rgba(255, 255, 255, 0.85);
    border-radius: 10px;
    z-index: 2;

    .contact-ways {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 2rem;
      position: relative;

      .whatsapp,
      .phone,
      .email {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 0.5rem;

        .cta {
          font-weight: var(--text-medium);
        }

        .img {
          width: 40px;
          height: 40px;
        }

        .link {
          color: var(--gray-80);
          font-size: var(--text-18);

          &:hover {
            color: var(--blue-50);
          }
        }
      }
    }

    .contact-form {
      width: 100%;
      padding: 5rem 0;
      border-radius: 7px;
      position: relative;
      display: grid;
      gap: 0 1rem;
      grid-template-areas:
        "name email"
        "company company"
        "message message";

      .name {
        grid-area: name;
      }

      .email {
        grid-area: email;
      }

      .company {
        grid-area: company;
      }

      .message {
        grid-area: message;
        margin-top: -10px;
      }

      .send {
        margin-top: -15px;
        grid-column: 2;
        justify-self: flex-end;
        background: var(--blue-50);
        border-radius: 5px;
        width: fit-content;
        height: 50px;
        padding: 0 40px;
        text-transform: uppercase;
        color: var(--white);
        font-weight: var(--text-semibold);
        font-size: var(--text-14);
        transition: all 0.3s ease-in-out;

        &:hover {
          background: var(--blue-100);
        }
      }

      .alert-email {
        position: absolute;
        bottom: 0;
      }
    }
  }
}

@media screen and (max-width: 1200px) {
  .contact {
    padding: 2rem 8rem;
    .contact-area {
      .contact-form {
        display: flex;
        flex-direction: column;

        .alert-email {
          bottom: -10px;
        }
      }
    }
  }
}

@media screen and (max-width: 940px) {
  .contact {
    padding: 2rem;

    .contact-area {
      padding: 2rem;
      flex-direction: column;
    }
  }
}

@media screen and (max-width: 375px) {
  .contact {

    .contact-area {
      .contact-form {
        .alert-email {
          bottom: -30px;
        }
      }
    }
  }
}
</style>