import Vue from "vue";
import App from "./App.vue";
import vuetify from "./plugins/vuetify";
import AOS from "aos";
import "aos/dist/aos.css";
import VueParticles from "vue-particles";

import "@/assets/styles/global.scss";

import router from "./router";
import store from "./store";
import i18n from './i18n'

Vue.config.productionTip = false;

Vue.use(VueParticles);

new Vue({
  created() {
    AOS.init({
      once: true,
      offset: 100,
      duration: 1000,
    });
  },

  vuetify,
  router,
  store,
  i18n,
  render: (h) => h(App)
}).$mount("#app");
