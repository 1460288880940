<template>
  <div class="clients">
    <SectionTitle
      :title="$t('home.clients.title')"
      :subtitle="$t('home.clients.subtitle')"
    />
    <div class="clients-logos" data-aos="fade-up">
      <swiper class="swiper-clients" :options="swiperClientsOption">
        <swiper-slide
          class="slide-item"
          v-for="(logo, i) in clientsLogos"
          :key="i"
        >
          <div class="logo">
            <img :src="require(`@/assets/img/clients/${logo}`)" :alt="logo" />
          </div>
        </swiper-slide>
        <div class="swiper-button-prev" slot="button-prev"></div>
        <div class="swiper-button-next" slot="button-next"></div>
      </swiper>
    </div>
  </div>
</template>

<script>
import SectionTitle from "@/components/SectionTitle";
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import "swiper/css/swiper.css";
export default {
  name: "Clients",
  components: {
    SectionTitle,
    Swiper,
    SwiperSlide,
  },
  data: () => {
    return {
      swiperClientsOption: {
        speed: 700,
        slidesPerView: 4,
        slidesPerGroup: 1,
        loop: true,
        centeredSlides: true,
        autoplay: {
          delay: 3000,
          disableOnInteraction: false,
        },
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
        breakpoints: {
          1024: {
            slidesPerView: 5,
          },
          600: {
            slidesPerView: 3,
          },
          0: {
            slidesPerView: 1,
          },
        },
      },
      clientsLogos: [
        "data_barbieri.png",
        "data_alumi.png",
        "data_labmedia.png",
        "data_pontoOoh.png",
        "data_basehm.png",
        "data_flymidia.png",
        "data_alternativa.png",
        "data_wam.png",
        "data_m2.png",
        "data_posterpublicidad.png",
        "data_maya.png",
        "data_atacama.png",
        "data_pontooh.png",
        "data_msouza.png",
        "data_infrontmidia.png",
        "data_pontoz.png",
        "data_mooh.png",
        "data_publik.png",
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
.clients {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 0px 4px 1px rgba(0, 0, 0, 0.1);

  background: var(--gray-30);
  padding: 2rem 0;
  border-radius: 10px;

  .clients-logos {
    max-width: 1440px;
    margin: 2rem 0;
    display: flex;
    justify-content: center;
    .slide-item {
      align-self: center;
      justify-self: center;
      .logo {
        display: flex;
        align-items: center;
        justify-content: center;

        img {
          max-width: 100px;
          width: 100%;
          height: auto;
        }
      }
    }

    .swiper-button-prev,
    .swiper-button-next {
      color: var(--blue-50);
    }
  }
}

@media screen and (max-width: 1400px) {
  .clients {
    .clients-logos {
      width: 90%;
    }
  }
}
</style>
