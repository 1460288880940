<template>
  <div class="section-title-wrapper" data-aos="fade-up">
    <h2 class="section-title">{{ title }}</h2>
    <p class="section-subtitle">{{ subtitle }}</p>
  </div>
</template>

<script>
export default {
  name: "SectionTitle",
  props: {
    title: {
      type: String,
    },
    subtitle: {
      type: String,
    },
  },
};
</script>

<style lang="scss" scoped>
.section-title-wrapper {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 20px;

  .section-title {
    color: var(--gray-80);
    font-size: var(--text-40);
    font-weight: var(--text-semibold);
  }

  .section-subtitle {
    font-size: var(--text-16);
    letter-spacing: 0.15px;
    color: var(--gray-20);
  }
}

@media screen and (max-width: 1340px) {
  .section-title-wrapper {
    .section-title {
      font-size: var(--text-28);
    }

    .section-subtitle {
      font-size: var(--text-14);
      line-height: 26px;
    }
  }
}

@media screen and (max-width: 900px) {
  .section-title-wrapper {
    .section-title {
      font-size: var(--text-26);
      line-height: 1.4;
      max-width: 400px;
    }
  }
}
</style>
