<template>
  <div class="page-hero" data-aos="fade-up">
    <div class="particles">
      <vue-particles
        color="#ccc"
        :particleOpacity="0.7"
        :particlesNumber="40"
        shapeType="circle"
        :particleSize="4"
        linesColor="#ccc"
        :linesWidth="1"
        :lineLinked="true"
        :lineOpacity="0.4"
        :linesDistance="250"
        :moveSpeed="3"
        :hoverEffect="true"
        hoverMode="grab"
        :clickEffect="true"
        clickMode="push"
      >
      </vue-particles>
    </div>
    <div class="text-content">
      <div class="title-wrapper">
        <slot name="hero-title"></slot>
        <slot name="hero-subtitle"></slot>
      </div>
      <div class="text-wrapper">
        <slot name="hero-text"></slot>
        <slot name="hero-cta"></slot>
      </div>
    </div>
    <div class="hero-img">
      <img
        class="img"
        :src="require(`@/assets/img/${img}`)"
        :alt="alt"
        data-aos="fade-up"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: "PageHero",
  props: {
    img: String,
    alt: String,
  },
};
</script>

<style lang="scss" scoped>
.page-hero {
  display: flex;
  justify-content: space-around;
  align-items: center;
  gap: 2rem;
  position: relative;
  padding: 4rem 2rem;

  .particles {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: left;
    background: var(--white);
    background-image: linear-gradient(120deg, #fefefe 60%, #efefef 100%);
    overflow: hidden;
  }
  .text-content {
    max-width: 640px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 2rem;
    z-index: 1;

    .title-wrapper {
      display: flex;
      flex-direction: column;
    }

    .text-wrapper {
      display: flex;
      flex-direction: column;
    }
  }

  .hero-img {
    text-align: end;
    z-index: 1;

    .img {
      max-width: 750px;
      width: 100%;
    }
  }
}

@media screen and (max-width: 1340px) {
  .page-hero {
    flex-direction: column;
    .text-content {
      gap: 1rem;
    }
  }
}

@media screen and (max-width: 900px) {
  .page-hero {
    .title-wrapper {
      margin-bottom: 20px;
    }

    .content {
      padding: 0 3rem;
    }
  }
}

@media screen and (max-width: 640px) {
  .page-hero {
    .content {
      padding: 0 3rem;
    }
  }
}

@media screen and (max-width: 420px) {
  .page-hero {
    .content {
      padding: 0 1rem;
    }
  }
}
</style>
