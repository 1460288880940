import { leadSignUp } from "../../../api/leadSignUp.api";
import i18n from "../../../i18n";

export const LeadSignUp = {
  state: {
    user: {
      name: null,
      email: null,
      mobile: null,
      company: null,
    },
    success: false,
    message: null,
  },
  mutations: {
    SET_LEAD(state, { name, email, mobile, company }) {
      state.user.name = name;
      state.user.email = email;
      state.user.mobile = mobile;
      state.user.company = company;
    },
  },
  actions: {
    async submitLead({ state, commit }, payload) {
      try {
        const { data } = await leadSignUp(payload);
        state.message = i18n.t("metrics.demo.response");
        state.success = data.success;
        commit("SET_LEAD", data);
        setTimeout(function() {
          state.message = null;
        }, 7000);
      } catch (error) {
        console.log(error);
      }
    },
  },
  getters: {},
  namespaced: true,
};
