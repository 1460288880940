import { httpClient } from "./index";

export const leadSignUp = ({ 
  name = null, 
  email = null,
  mobile = null,
  company = null,
}) => httpClient.post("/user/lead-sign-up", {
    leadsignup: {
      name: name,
      email: email,
      mobile: mobile,
      company: company,
    },
  });