<template>
  <div class="container" data-aos="fade-up">
    <div class="hero">
      <div class="text-content">
        <div class="hero-title">
          <p class="overline">{{ subtitle }}</p>
          <h1 class="title">{{ title }}</h1>
        </div>
        <div class="text">
          <p v-html="firstParagraph"></p>
          <p>{{ secondParagraph }}</p>
        </div>
      </div>
      <div class="hero-image">
        <img :src="img" :alt="title" class="img" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Slide",
  props: {
    subtitle: String,
    title: String,
    firstParagraph: String,
    secondParagraph: String,
    img: String,
  },
  data() {
    return {
      buttonIcon: "arrow-right.svg",
    };
  },
};
</script>

<style lang="scss" scoped>
.container {
  width: 100%;
  .hero {
    display: flex;
    justify-content: space-around;
    align-items: center;
    gap: 2rem;
    padding: 6rem 2rem;

    .text-content {
      display: flex;
      flex-direction: column;
      max-width: 680px;
      gap: 2rem;

      .hero-title {
        .title {
          font-size: var(--text-44) !important;
          font-weight: var(--text-semibold);
          line-height: 1.3;
          font-family: "Open Sans", sans-serif !important;
        }

        .overline {
          text-transform: uppercase;
          color: var(--gray-20);
          opacity: 0.6;
          margin-bottom: 0;
        }
      }

      .text {
        display: flex;
        flex-direction: column;
        gap: 2rem;
        p {
          line-height: 1.8;
          font-size: var(--text-18);
          margin: 0;
          color: var(--gray-20);
        }
      }
    }

    .hero-image {
      text-align: end;

      img {
        max-width: 750px;
        width: 100%;
      }
    }
  }
}

@media screen and (max-width: 1340px) {
  .container {
    .hero {
      flex-direction: column;
      padding: 0 1rem;

      .text-content {
        gap: 1rem;
        margin-bottom: 2rem;
      }
    }
  }
}

@media screen and (max-width: 900px) {
  .container {
    .hero {
      .text-content {
        .hero-title {
          .title {
            font-size: var(--text-30) !important;
            text-align: center;
          }

          .overline {
            text-align: center;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 540px) {
  .container {
    .hero {
      padding: 0;
      .text-content {
        .hero-title {
          .title {
            font-size: var(--text-26) !important;
          }
        }

        .text {
          p {
            font-size: var(--text-16);
          }
        }
      }
    }
  }
}
</style>
